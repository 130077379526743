// Based on https://gist.github.com/JonCatmull/e00afb1c96298a4e386ea1b5d091702a
import {Pipe, PipeTransform} from '@angular/core';
import {Epoch} from '../../models/local';

// Epochs
const epochs: any[] = [
  [Epoch.Year, 31536000],
  [Epoch.Month, 2592000],
  [Epoch.Day, 86400],
  [Epoch.Hour, 3600],
  [Epoch.Minute, 60],
  [Epoch.Second, 1]
];

/*
 * Turn Date into realtive date (e.g. 5 days ago)
 * Usage:
 *   value | relativeDate
 * Example:
 *   {{ 86400 |  relativeDate}}
 *   formats to: '1 day ago'
*/
@Pipe({name: 'relativeDate'})
export class RelativeDatePipe implements PipeTransform {

  getDuration(timeAgoInSeconds: number, epochConstraint: Epoch) {
    const allowedEpochs = epochs.slice(epochs.findIndex(([name, seconds]) => name === epochConstraint));

    for (const [name, seconds] of allowedEpochs) {
      const interval = Math.floor(Math.abs(timeAgoInSeconds) / seconds);
      if (interval >= 1) {
        return {
          interval: Math.abs(interval),
          epoch: name
        };
      }
    }
    return {
      interval: 0,
      epoch: 'segundo'
    };
  }

  transform(dateStamp: number | Date, epochConstraint: Epoch = Epoch.Year): string {
    const timeAgoInSeconds = Math.floor((new Date().getTime() - new Date(dateStamp).getTime()) / 1000);
    const {interval, epoch} = this.getDuration(timeAgoInSeconds, epochConstraint);
    const suffix = interval === 1 ? '' : (epoch == Epoch.Month ? 'es' : 's');
    const ago = timeAgoInSeconds < 0 ? '' : 'Hace ';
    const inLabel = timeAgoInSeconds < 0 ? 'En ' : '';

    return `${ago}${inLabel}${interval} ${epoch}${suffix}`;
  }
}
