import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../services/auth.service';

@Directive({
  selector: '[appSecureDownload]'
})
export class SecureDownloadDirective implements OnDestroy {

  private localObjectUrl: string;

  @Input('appSecureDownload') resourceUrl: string;

  constructor(private httpClient: HttpClient,
              private authService: AuthService) {
  }

  ngOnDestroy(): void {
    if (this.localObjectUrl) {
      window.URL.revokeObjectURL(this.localObjectUrl);
    }
  }

  @HostListener('click', ['$event']) download(event: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    if (!this.resourceUrl) {
      console.warn('[ SecureDownloadDirective ] resourceURL is not provided.');
      return;
    }

    const headers: HttpHeaders = new HttpHeaders({
      'user-token': this.authService.getUserToken()
    });
    this.httpClient.get(this.resourceUrl, {headers, responseType: 'blob'}).toPromise().then(response => {
      this.localObjectUrl = window.URL.createObjectURL(response);
      window.open(this.localObjectUrl, '_blank');
    }).catch(error => {
      console.error('[ SecureDownloadDirective ] Error downloading file: ', error);
    });

  }

}
