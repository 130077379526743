import { Injectable } from '@angular/core';
import { TableDefaults, TableRequestBody } from 'src/app/models/local';
import { Trip } from 'src/app/models/remote';

@Injectable({
  providedIn: 'root'
})
export class TripService {

  constructor() {
  }

  public getTripsForManage(tableRequestBody: TableRequestBody = {
    pageOffset: 0,
    pageSize: TableDefaults.PageSize
  }): Promise<{ trips: Trip[], totalRows: number }> {
    return Backendless.CustomServices.invoke('AdminService', 'getTripsForManage', tableRequestBody);
  }

  public getTripById(tripObjectId: string): Promise<Trip> {
    return Backendless.CustomServices.invoke('AdminService', 'getTripById', tripObjectId);
  }
}
