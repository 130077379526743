import {NgModule} from '@angular/core';
import {DebounceDirective} from './directives/debounce.directive';
import {CommonModule} from '@angular/common';
import {FileSizePipe} from './pipes/file-size.pipe';
import {RelativeDatePipe} from './pipes/relative-date.pipe';
import {SecureDownloadDirective} from './directives/secure-download.directive';

@NgModule({
  declarations: [
    DebounceDirective,
    FileSizePipe,
    RelativeDatePipe,
    SecureDownloadDirective
  ],
  exports: [
    DebounceDirective,
    FileSizePipe,
    RelativeDatePipe,
    SecureDownloadDirective
  ],
  imports: [
    CommonModule
  ]
})
export class SharedModule {
}
